<template>
  <div class="docUpload">
    <div class="header">
      <router-link >
        <img src="../assets/logo-transparent-cropped.png" alt="" />
      </router-link>
    </div>

    <div class="content">
      <div class="container">
        <h2 class="text-center pt-4 mb-4">Upload Document</h2>
        <b-form
          @submit.prevent="saveForm"
          class="w-100 needs-validation"
          novalidate
        >
          <div class="row">
            <div class="col-12 col-sm-6">
              <div class="fileUploadContainer mb-4">
                <img
                  src="../assets/img/svg/file-upload.svg"
                  alt="file upload"
                />
                <p class="m-0">Click to upload document</p>
                <p>(PDF/JPG/PNG Max 5MB)</p>
                <b-form-file
                  accept=".jpg, .png, .pdf"
                  @change="previewImage($event)"
                ></b-form-file>
                <img src="" alt="" id="previewImg" />
                <p id="previewImgName" class="m-0"></p>
              </div>

              <b-form-group id="input-group-1" class="mb-4">
                <div class="optionSelect">
                  <b-form-select
                    v-model="form.dosage"
                    :options="dosages"
                    id="input-1"
                    aria-describedby="input-1-live-feedback"
                  >
                    <b-form-select-option :value="null" disabled
                      >Dosage</b-form-select-option
                    >
                  </b-form-select>
                </div>
              </b-form-group>
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  w-sm-75
                  mb-3
                "
              >
                <b-form-checkbox
                  size="lg"
                  v-model="form.certified"
                  class="mb-3"
                >
                  <span class="checkbox-content"> Certify</span>
                </b-form-checkbox>

                <div class="feeContainer">
                  <span class="text-muted">Fee</span> ($25.00)
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="d-flex justify-content-start align-items-center mb-3">
                <span class="mx-3">{{ currentDate() }}</span>
                <b-form-group id="input-group-2">
                  <div class="optionSelect">
                    <b-form-select
                      v-model="form.type"
                      :options="VaccineTypes"
                      id="input-2"
                      aria-describedby="input-2-live-feedback"
                    >
                      <b-form-select-option :value="null" disabled
                        >Type</b-form-select-option
                      >
                    </b-form-select>
                  </div>
                </b-form-group>
              </div>

              <b-form-group
                id="input-group-3"
                label="Vaccinated On:"
                label-for="input-3"
                class="mt-2"
              >
                <div class="optionSelect mb-3">
                  <b-form-select
                    v-model="form.vaccinatedDay"
                    :options="days"
                    id="input-3"
                    aria-describedby="input-3-live-feedback"
                    required
                  >
                    <b-form-select-option :value="null" disabled
                      >Day</b-form-select-option
                    >
                  </b-form-select>
                </div>
              </b-form-group>

              <div class="row">
                <div class="col-12 mb-3">
                  <b-form-datepicker
                    id="datepicker-dateformat"
                    v-model="form.vaccinatedDate"
                    placeholder="DD/MM/YYYY"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    locale="en-uk"
                    required
                  ></b-form-datepicker>
                </div>
                <!-- <div class="col-12 col-lg-6 mb-3">
                  <b-form-timepicker
                    v-model="form.vaccinatedTime"
                    show-seconds
                    locale="en"
                  ></b-form-timepicker>
                </div> -->
              </div>

              <b-form-group
                id="input-group-4"
                label="Lab/Clinic Name:"
                label-for="input-4"
              >
                <Autocomplete
                  :items="items"
                  v-model="item"
                  @change="updateItems"
                  :component-item="template"
                  :get-label="getLabel"
                  @item-selected="selectedInstitute"
                >
                </Autocomplete>
              </b-form-group>
            </div>
          </div>
          <div class="col-10 col-md-6 mx-auto">
            <b-button
              type="submit"
              variant="primary"
              class="saveBtn w-100 mt-5 mb-3"
            >
              Save
            </b-button>
          </div>
        </b-form>
      </div>
    </div>

    <div class="footer">
      <p>Copyright JabComp Ⓒ {{ new Date().getFullYear() }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Autocomplete from "v-autocomplete";
import "v-autocomplete/dist/v-autocomplete.css";
import ItemTemplate from "./ItemTemplate.vue";

export default {
  components: {
    Autocomplete,
  },
  data() {
    return {
      item: {},
      items: [],
      template: ItemTemplate,
      form: {
        type: null,
        instituteId: 2,
        status: "pending",
        // date: "",
        vaccinatedDay: null,
        vaccinatedDate: null,
        certified: false,
        dosage: null,
        // vaccinatedTime: null,
        labName: "",
        certified: "",
      },
      vaccineId: "",

      dosages: ["1st Dosage", "2nd Dosage", "Booster"],
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      configData: {},
      VaccineTypes: [],
    };
  },
  created() {
     if (this.$store.getters.getAuth == false) {
      this.$router.push("/login");
    }
    axios
      .get("https://api.vaxtraxglobal.com/api/v1/config/1")
      .then(({ data }) => {
        this.configData = data;

        this.configData.vaccineType.forEach((type) => {
          this.VaccineTypes.push(type.type);
        });
      })
      .catch((resp) => console.log(resp));
      
  },
  methods: {
    getLabel(item) {
      console.log(item);
      return item.name;
    },
    updateItems(text) {
      if (text.length > 0) {
        axios
          .get(`https://api.vaxtraxglobal.com/api/v1/institute/${text}`, {
            headers: {
              role: "user",
            },
          })
          .then(({ data }) => {
            this.items = [];
            // console.log(data);
            data.institute.forEach((inst) => {
              this.items.push({ id: inst.id, name: inst.name });
            });
            console.log(this.items);
          })
          .catch((resp) => {
            console.log(resp);
          });
      } else {
        this.items = [];
      }
    },
    selectedInstitute(item) {
      console.log(item.id);
      this.form.instituteId = item.id;
    },
    previewImage(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          document.getElementById("previewImg").src = reader.result;
          document.getElementById("previewImgName").style.display = "block";
          document.getElementById("previewImgName").innerHTML =
            input.target.files[0].name;
        };
        reader.readAsDataURL(input.target.files[0]);
        this.form.file = input.target.files[0];
      }
    },
    currentDate() {
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;

      let yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      today = dd + "/" + mm + "/" + yyyy;
      return today;
    },
    saveForm() {
      if (!this.form.dosage || !this.form.type) {
        alert("Please fill all the fields");
        return;
      }
      console.log(this.configData);
      // return;
      this.configData.vaccineType.forEach((vaccine) => {
        if (vaccine.type === this.form.type) {
          this.vaccineId = vaccine.id;
        }
      });
      var storeData = this.$store.getters.getData;

      let uploadData = new FormData();
      uploadData.append("userId", storeData.id);
      uploadData.append("type", this.vaccineId);
      uploadData.append("instituteId", this.form.instituteId);
      uploadData.append("status", this.form.status);
      uploadData.append("certified", this.form.certified);
      uploadData.append("date", this.form.vaccinatedDate);
      uploadData.append("file", this.form.file);
      for (var pair of uploadData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      axios
        .post("https://api.vaxtraxglobal.com/api/v1/vaccine/upload", uploadData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log(data);
          let dataToPush = {vaccine:data.vaccine};
          this.$store.dispatch("setData", dataToPush);
          // console.log(this.$store.getters.getData);
          this.$router.push("/upload-success");
        })
        .catch((resp) => {
          console.log(resp);
          alert("Error uploading file. Please try again later.");
        });
      // alert("form submitted");
    },
  },
};
</script>

<style lang="scss" scoped>
.docUpload {
  position: relative;
  min-height: 100vh;
  padding-bottom: 50px;
  .header {
    width: 100%;
    height: 100px;
    background: #005eaa33;
    text-align: center;
    position:relative;
    // padding: 15px;
    border-radius: 0 0 70px 70px;
    img {
      height: 100%;
      max-width: 90%;
      object-fit: contain;
    }
  }

  .content {
    height: 100%;
    width: 100%;

    > h2 {
      font-weight: 800 !important;
    }

    .fileUploadContainer {
      box-shadow: 0px 5px 9px #33333326;
      border: 1px solid #ebebeb;
      border-radius: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 20px;
      position: relative;
      overflow: hidden;

      &:hover {
        box-shadow: 0 0 5px rgb(214, 213, 213);
        color: #747474;
      }

      .b-form-file {
        position: absolute;
        height: 100%;
        width: 100%;
        font-size: 10rem;
        opacity: 0;
      }

      #previewImg {
        max-height: 150px;
        max-width: 100%;
        object-fit: contain;
      }
      #previewImgName {
        display: none;
        color: rgb(233, 108, 108);
      }
    }

    form {
      .form-group {
        font-size: 0.9rem !important;
        margin: 10px 0;
        width: 100%;
      }

      input {
        border-radius: 90px;
        box-shadow: 0px 5px 9px #33333326;
        border: 1px solid #ebebeb;
        padding: 0 20px;
      }

      select {
        appearance: none;
        background-color: transparent;
        border: none;
        padding: 0 10px;
        margin: 0;
        width: 100%;
        font-family: inherit;
        font-size: inherit;
        cursor: inherit;
        line-height: inherit;
        outline: none;

        &::-ms-expand {
          display: none;
        }

        &:focus,
        &:active {
          border-color: transparent;
          outline: 0;
          -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0),
            0 0 8px rgba(102, 175, 233, 0);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0),
            0 0 8px rgba(102, 175, 233, 0);
        }
      }

      .optionSelect {
        width: 100%;
        border: 1px solid var(--select-border);
        border-radius: 0.25em;
        padding: 0 0.5em;
        cursor: pointer;
        line-height: 1.1;
        border-radius: 90px;
        box-shadow: 0px 5px 9px #33333326;
        border: 1px solid #ebebeb;
      }

      .custom-checkbox {
        .checkbox-content {
          font-size: 0.9rem;
        }
      }

      .feeContainer {
        border-radius: 90px;
        box-shadow: 0px 5px 9px #33333326;
        border: 1px solid #ebebeb;
        padding: 10px 20px;
      }

      .b-form-btn-label-control.form-control {
        border-radius: 90px;
        box-shadow: 0px 5px 9px #33333326;
        border: 1px solid #ebebeb;
      }
      .saveBtn {
        border-radius: 90px;
        box-shadow: 0px 5px 9px #33333326;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #005eaa33;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 20px 20px 0 0;

    > p {
      margin: 0;
    }
  }
}
</style>