<template>
  <div class="template">
    <span>{{ item.name }}</span>
  </div>
</template>

<script>
export default {
  name: "ItemTemplate",
  props: {
    item: { required: true },
  },
};
</script>
